import * as React from "react";
import Navbar from "../components/navbar";
import "../components/sass/main.scss";
import Footer from "./footer";

const Layout = ({ altBg, children }) => {
  return (
    <div id="wrapper" style={{backgroundColor: altBg ? "#ffffff" : "#eee0ee"}}>
      <div id="main">
        <div className="inner">
          <Navbar />
          <main>{children}</main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
