import axios from "axios";
import * as React from "react";

const Newsletter = (props) => {
  const [message, setMessage] = React.useState();
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const { title, description, image } = props;

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let email = e.target.elements.email.value;
    if (validateEmail(email)) {
      setButtonDisabled(true);
      axios
        .post("https://a4u0zoa7le.execute-api.us-east-2.amazonaws.com/store", {
          email: e.target.elements.email.value,
          list_id: "okaycarbon",
        })
        .finally(() => setMessage("Thank you!"));
    }
  };

  return (
    <>
      <form id="signup-form" method="post" action="#" onSubmit={onSubmit}>
        <input type="email" name="email" id="email" placeholder="email" />
        <button type="submit">
          <i className="icon solid fa-sort-up"></i>
        </button>
      </form>
      {message && <div className="thank-you">
        {message}
      </div>}
    </>
  );
};

export default Newsletter;
