import * as React from "react";
import Newsletter from "./newsletter";

const Footer = () => (
  <div className="full-width-bg-color">
    <footer>
      <div className="row gtr-150">
        <div
          className="col-4 col-5-medium col-12-small"
          style={{ paddingTop: "8px" }}
        >
          <div>
            <a className="button small alt no-border" href="/">
              instagram
            </a>
          </div>
          <div>
            <a className="button small alt no-border" href="/">
              contact
            </a>
          </div>
        </div>
        <div className="col-6 col-7-medium col-12-small">
          <h2 className="alt bold" style={{ lineHeight: "1.2em" }}>
            sign up for our newsletter:
          </h2>
          <Newsletter />
        </div>
      </div>
      <div className="row gtr-150">
        <div className="col-2 col-12-small">
          <div>
            <a className="button tiny alt no-border no-padding" href="/">
              Affiliate Disclosure
            </a>
          </div>
          <div>
            <a className="button tiny alt no-border no-padding" href="/">
              Terms & Conditions
            </a>
          </div>
          <div>
            <a className="button tiny alt no-border no-padding" href="/">
              Privacy & Cookies
            </a>
          </div>
          <div>
            <a className="button tiny alt no-border no-padding" href="/">
              © 2021 okay carbon
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
);

export default Footer;
