import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  const [miniNav, setMiniNav] = React.useState(false);

  const handleMobileMenu = () => {
    setOpen((prev) => !prev);
  };

  // The debounce function receives our function as a parameter
  const debounce = (fn) => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame;

    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params) => {
      // If the frame variable has been defined, clear it now, and queue for next frame
      if (frame) {
        cancelAnimationFrame(frame);
      }

      // Queue our function call for the next frame
      frame = requestAnimationFrame(() => {
        // Call our function and pass any params we received
        fn(...params);
      });
    };
  };

  // Reads out the scroll position and stores it in the data attribute
  // so we can use it in our stylesheets
  const storeScroll = () => {
    if (window.scrollY > 150) {
      setMiniNav(true);
    } else {
      setMiniNav(false);
    }
  };

  React.useEffect(() => {
    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener("scroll", debounce(storeScroll), {
      passive: true,
    });

    // Update scroll position for first time
    storeScroll();
  }, []);

  return (
    <>
      <div
        className={`topbar full-width-bg-color ${miniNav ? "scrolled" : ""}`}
      >
        <div className="top-banner">
          <p>How we live now</p>
        </div>
        <div className={`navbar`}>
          <div className="nav-item">
            <Link
              to="/shop"
              className="button alt underline-hover no-border no-padding"
            >
              the shop
            </Link>
          </div>
          <div className="nav-item">
            <Link
              to="/conversations"
              className="button alt underline-hover no-border no-padding"
            >
              conversations
            </Link>
          </div>
          <div className="logo">
            <Link to="/">
              <StaticImage
                placeholder="tracedSVG"
                src="../images/okay_carbon.png"
                alt="Okay Carbon logo"
                layout="constrained"
                width={220}
                height={220}
                tracedSVGOptions={{ color: "#ff815e" }}
              />
            </Link>
          </div>
          <div className="nav-item">
            <Link
              to="/research"
              className="button alt underline-hover no-border no-padding"
            >
              research
            </Link>
          </div>
          <div className="nav-item">
            <Link
              to="/about"
              className="button alt underline-hover no-border no-padding"
            >
              about
            </Link>
          </div>
          <div className={`nav-item hamburger ${open && "active"}`}>
            <span className="icon solid fa-bars" onClick={handleMobileMenu} onKeyDown={handleMobileMenu} role="button" tabIndex={0}>
              <span className="label">Menu</span>
            </span>
          </div>
        </div>
      </div>
      <div
        className={`mobile-menu ${open && "active"} ${
          miniNav ? "scrolled" : ""
        }`}
      >
        <Link to="/shop" className="button no-border no-padding">
          the shop
        </Link>
        <Link to="/conversations" className="button no-border no-padding">
          conversations
        </Link>
        <Link to="/research" className="button no-border no-padding">
          research
        </Link>
        <Link to="/about" className="button no-border no-padding">
          about
        </Link>
      </div>
    </>
  );
};

export default Navbar;
